import axios from "axios";
import { state } from "../../store.js";
//import { atualizarCampos } from "../../components/cronograma/editartarefamodal/editartarefa.js";
const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
});

// Ajusta a largura do dropdown de forma responsiva
export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Obra = {
    async initializeTasks() {
        let projeto_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            projeto_id = this.task;
        } else if (this.task && this.task.projeto_id) {
            projeto_id = this.task.projeto_id;
        } else {
            console.warn("projeto_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchObra(projeto_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusprojeto-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.6%" : "4%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarObra(valor) {
        clearTimeout(this.debounceTimeoutObra);

        this.debounceTimeoutObra = setTimeout(async () => {
            this.queryObra = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchObra(state.pesquisarObra.projeto_id, this.queryObra, state.pesquisarObra.pesquisar2, state.pesquisarObra.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarCliente(valor) {
        clearTimeout(this.debounceTimeoutCliente);

        this.debounceTimeoutCliente = setTimeout(async () => {
            this.queryCliente = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchObra(state.pesquisarObra.projeto_id, state.pesquisarObra.pesquisar1, this.queryCliente, state.pesquisarObra.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : null;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchObra(state.pesquisarObra.projeto_id, state.pesquisarObra.pesquisar1, state.pesquisarObra.pesquisar2, fechada);

            this.tasks = tasks;

            console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    ObraSelecionada(task) {
        //console.warn("Tarefa selecionada:", task);
    
        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.obra = task.obra;
            state.EditarTarefaCronograma.projeto_id = task.projeto_id;
    
            // Chama a função para atualizar os campos
            //atualizarCampos(this);
    
            // Emite o evento e fecha o modal
            this.$emit("fecharModalObra", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },
}

export async function fetchObra(projeto_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (projeto_id !== undefined) {
            state.pesquisarObra.projeto_id = projeto_id;
        } else {
            state.pesquisarObra.projeto_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarObra.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarObra.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarObra.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarObra.pesquisar2 = null;
        }

        if (pesquisar3 !== undefined) {
            state.pesquisarObra.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarObra.pesquisar3 = null;
        }

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            projeto_id: state.pesquisarObra.projeto_id,
            pesquisar1: state.pesquisarObra.pesquisar1,
            pesquisar2: state.pesquisarObra.pesquisar2,
            pesquisar3: state.pesquisarObra.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);

        // Faça a requisição para a API
        const response = await api.get("/obra", { params });

        const tasks = response.data.map((task) => ({
            projeto_id: task.PROJETO_REGISTRO,
            obra: task.OBRA_DESCRICAO || "",
            cliente: task.CLIENTE_DESCRICAO || "",
            revisao: task.PROJETO_REV || "",
            codigo_orcamento: task.C1 || "",
            codigo_projeto: task.C2 || "",
            statusprojeto: task.TIPO1 || "",
        }));

        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}















