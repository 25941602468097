
<template>
  <v-app>
    <!-- Sidebar ajustável -->
    <v-navigation-drawer
      app
      v-model="drawer"
      width="40"
      :mini-variant="mini"
      @click="toggleDrawer"
    >
      <div class="sidebar-logo">
        <img
          src="/123/icones/icone_preto.png"
          alt="logo"
          width="23"
          height="23"
          style="cursor: pointer"
        />
      </div>
      <v-divider></v-divider>

      <v-container class="cronograma-container">
        <v-row
          align="center"
          class="custom-item-cronograma"
          @click="openDrawer('menu')"
        >
          <v-col cols="auto">
            <v-icon>mdi-database</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="cronograma-container">
        <v-row
          align="center"
          class="custom-item-cronograma"
          @click="limpar"
          style="cursor: pointer"
        >
          <v-col cols="auto">
            <v-icon title="Limpar tudo">mdi-delete</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <div class="parent-container">
      <div app class="custom-app-bar-cronograma">
        <div class="selects-container">
          <multiselect
            v-model="carregarObra"
            :options="dataCarregarObra || []"
            label="label"
            track-by="value"
            @update:modelValue="Selecionar_obra"
            dense
            :multiple="true"
            placeholder="Selecione uma Obra"
            class="custom-select-cronograma select-carregar-obra"
          ></multiselect>
          <multiselect
            v-model="carregarSetor"
            :options="dataCarregarSetor"
            label="label"
            track-by="value"
            placeholder="Selecione uma Setor"
            searchable
            clearable
            @update:modelValue="Selecionar_setor"
            :multiple="true"
            class="custom-select-cronograma select-carregar-setor"
          ></multiselect>
          <multiselect
            v-model="carregarSubsetor"
            :options="dataCarregarSubsetor"
            label="label"
            track-by="value"
            placeholder="Selecione uma Subsetor"
            searchable
            clearable
            @update:modelValue="Selecionar_subsetor"
            :multiple="true"
            class="custom-select-cronograma select-carregar-subsetor"
          ></multiselect>
          <multiselect
            v-model="carregarPlace"
            :options="dataCarregarPlace"
            label="label"
            track-by="value"
            placeholder="Selecione uma Place"
            searchable
            clearable
            @update:modelValue="Selecionar_place"
            :multiple="true"
            class="custom-select-cronograma select-carregar-place"
          ></multiselect>
          <multiselect
            v-model="carregarEtapa"
            :options="dataCarregarEtapa"
            label="label"
            track-by="value"
            placeholder="Selecione uma Etapa"
            searchable
            clearable
            @update:modelValue="Selecionar_etapa"
            :multiple="true"
            class="custom-select-cronograma select-carregar-etapa"
          ></multiselect>
          <multiselect
            v-model="carregarSubetapa"
            :options="dataCarregarSubetapa"
            label="label"
            track-by="value"
            placeholder="Selecione uma Subetapa"
            searchable
            clearable
            @update:modelValue="Selecionar_subetapa"
            :multiple="true"
            class="custom-select-cronograma select-carregar-subetapa"
          ></multiselect>
          <multiselect
            v-model="carregarTarefa"
            :options="dataCarregarTarefa"
            label="label"
            track-by="value"
            placeholder="Selecione uma Tarefa"
            searchable
            clearable
            @update:modelValue="Selecionar_tarefa"
            :multiple="true"
            class="custom-select-cronograma select-carregar-tarefa"
          ></multiselect>
          <multiselect
            v-model="carregarEmpresa"
            :options="dataCarregarEmpresa"
            label="label"
            track-by="value"
            placeholder="Selecione uma Empresa"
            searchable
            clearable
            @update:modelValue="Selecionar_empresa"
            :multiple="true"
            class="custom-select-cronograma select-carregar-empresa"
          ></multiselect>
          <multiselect
            v-model="selectedStartDateOption"
            :options="dataOpcoes"
            label="label"
            track-by="value"
            placeholder="Selecione uma data"
            searchable
            clearable
            class="custom-select-cronograma select-carregar-data-inicio"
            :class="{
              'has-selection':
                selectedStartDateOption && selectedStartDateOption.label,
            }"
          ></multiselect>
          <multiselect
            v-model="selectedEndDateOption"
            :options="dataOpcoes"
            label="label"
            track-by="value"
            placeholder="Selecione uma data"
            searchable
            clearable
            class="custom-select-cronograma select-carregar-data-termino"
            :class="{
              'has-selection':
                selectedEndDateOption && selectedEndDateOption.label,
            }"
          ></multiselect>
        </div>
      </div>
      <div class="custom-main-cronograma">
        <div class="cronograma-header-wrapper" ref="headerWrapper">
          <table class="cronograma-table">
            <colgroup>
              <col class="task-col" />
              <col v-if="isSubtarefaVisible" class="subtarefa-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isUdeVisible" class="ude-col" />
              <col v-if="isQde1Visible" class="qde1-col" />
              <col v-if="isPorcentagemVisible" class="porcentagem-col" />
              <col v-if="isstinicioVisible" class="stinicio-col" />
              <col v-if="isduracao00Visible" class="duracao00-col" />
              <col v-if="isinicio00Visible" class="inicio00-col" />
              <col v-if="istermino00Visible" class="termino00-col" />
              <col v-if="isintervalo00Visible" class="intervalo00-col" />
              <col v-if="isstterminoVisible" class="sttermino-col" />
              <col v-if="isstterminoVisible" class="status-col" />
              <col v-for="date in dateRange" :key="date" class="mes-col" />
            </colgroup>
            <thead>
              <tr>
                <th
                  rowspan="2"
                  class="task-header"
                  @dblclick="expandAllColumns"
                >
                  Tarefas
                </th>
                <th
                  v-if="isSubtarefaVisible"
                  class="subtarefa-header"
                  rowspan="2"
                  @click="handleClickSubtarefa"
                >
                  Subtarefa
                </th>
                <th
                  v-if="isQdeVisible"
                  class="qde-header"
                  colspan="3"
                  rowspan="2"
                  @click="handleClickQde"
                >
                  Qde
                </th>
                <th
                  v-if="isUdeVisible"
                  class="ude-header"
                  rowspan="2"
                  @click="handleClickUde"
                >
                  Unidade
                </th>
                <th
                  v-if="isQde1Visible"
                  class="qde1-header"
                  rowspan="2"
                  @click="handleClickQde1"
                >
                  Qde
                </th>
                <th
                  v-if="isPorcentagemVisible"
                  class="porcentagem-header"
                  rowspan="2"
                  @click="handleClickPorcentagem"
                >
                  %
                </th>
                <th
                  v-if="isduracao00Visible"
                  class="duracao00-header"
                  rowspan="2"
                  colspan="2"
                  @click="handleClickDuracao00"
                >
                  Duracao
                </th>
                <th
                  v-if="isinicio00Visible"
                  class="inicio00-header"
                  rowspan="2"
                  @click="handleClickinicio00"
                >
                  Inicio
                </th>
                <th
                  v-if="istermino00Visible"
                  class="termino00-header"
                  rowspan="2"
                  @click="handleClicktermino00"
                >
                  Termino
                </th>
                <th
                  v-if="isintervalo00Visible"
                  class="intervalo00-header"
                  colspan="3"
                  rowspan="2"
                  @click="handleClickIntervalo00"
                >
                  Intervalo
                </th>
                <!-- Cabeçalho de anos com colspan baseado nos meses de cada ano -->
                <th
                  v-for="year in uniqueYears"
                  :key="year"
                  class="ano-header"
                  :colspan="
                    dateRange.filter((date) => date.getFullYear() === year)
                      .length
                  "
                >
                  {{ year }}
                </th>
                <th class="cronograma-ajuste-tabela"></th>
              </tr>
              <tr>
                <!-- Cabeçalho dos meses dentro de cada ano -->
                <th v-for="date in dateRange" :key="date" class="mes-header">
                  {{ formatMonth(date) }}
                </th>
                <th class="cronograma-ajuste-tabela"></th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          class="cronograma-content-wrapper"
          ref="contentWrapper"
          @scroll="syncScroll"
        >
          <table class="cronograma-table">
            <colgroup>
              <col class="task-col" />
              <col v-if="isSubtarefaVisible" class="subtarefa-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isQdeVisible" class="qde-col" />
              <col v-if="isUdeVisible" class="ude-col" />
              <col v-if="isQde1Visible" class="qde1-col" />
              <col v-if="isPorcentagemVisible" class="porcentagem-col" />
              <col v-if="isstinicioVisible" class="stinicio-col" />
              <col v-if="isduracao00Visible" class="duracao00-col" />
              <col v-if="isinicio00Visible" class="inicio00-col" />
              <col v-if="istermino00Visible" class="termino00-col" />
              <col v-if="isintervalo00Visible" class="intervalo00-col" />
              <col v-if="isstterminoVisible" class="sttermino-col" />
              <col v-if="isstterminoVisible" class="status-col" />
              <col v-for="date in dateRange" :key="date" class="mes-col" />
            </colgroup>
            <tbody>
              <tr
                :id="task.id"
                class="tr-id"
                v-for="task in tasks"
                :key="task.id"
                :class="{
                  'setor-hidden': task.tipo === 'Setor' && !task.visible,
                  'subsetor-hidden': task.tipo === 'Subsetor' && !task.visible,
                  'place-hidden': task.tipo === 'Place' && !task.visible,
                  'etapa-hidden': task.tipo === 'Etapa' && !task.visible,
                  'subetapa-hidden': task.tipo === 'Subetapa' && !task.visible,
                  'tarefa-hidden': task.tipo === 'Tarefa' && !task.visible,
                }"
              >
                <td
                  :class="
                    task.tipo === 'Projeto'
                      ? ['cronograma-projeto']
                      : task.tipo === 'Setor'
                      ? ['cronograma-setor']
                      : task.tipo === 'Subsetor'
                      ? ['cronograma-subsetor']
                      : task.tipo === 'Place'
                      ? ['cronograma-place']
                      : task.tipo === 'Etapa'
                      ? ['cronograma-etapa']
                      : task.tipo === 'Subetapa'
                      ? ['cronograma-subetapa']
                      : task.tipo === 'Tarefa'
                      ? ['cronograma-tarefa', 'pinta-barra']
                      : []
                  "
                  class="task-cell"
                  :data-task-id="task.id"
                  :data-task-para="task.id"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  :colspan="
                    task.tipo === 'Projeto'
                      ? isQdeVisible && isSubtarefaVisible
                        ? 5 // Ambas visíveis
                        : !isQdeVisible && !isSubtarefaVisible
                        ? 1 // Ambas ocultas
                        : !isSubtarefaVisible
                        ? 4 // Apenas subtarefa oculta
                        : 2 // Apenas Qde oculta
                      : task.tipo === 'Setor' ||
                        task.tipo === 'Subsetor' ||
                        task.tipo === 'Place' ||
                        task.tipo === 'Etapa' ||
                        task.tipo === 'Subetapa'
                      ? isQdeVisible && isSubtarefaVisible
                        ? 2 // Ambas visíveis
                        : !isQdeVisible && !isSubtarefaVisible
                        ? 1 // Ambas ocultas
                        : !isSubtarefaVisible
                        ? 1 // Apenas subtarefa oculta
                        : 3 // Ajuste para colunas extras
                      : null
                  "
                  @click="handleTaskClick(task)"
                  @dblclick="
                    task.tipo === 'Projeto' ? handleDoubleClick() : null
                  "
                >
                  {{ task.tarefa }}
                </td>
                <td
                  v-if="isSubtarefaVisible && task.tipo === 'Tarefa'"
                  class="cronograma-tarefa-a subtarefa-cell "
                  colspan="0"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                >
                  {{ task.subtarefa + " / " + task.oempresa }}
                </td>
                <td
                  v-if="
                    isQdeVisible &&
                    [
                      'Setor',
                      'Subsetor',
                      'Place',
                      'Etapa',
                      'Subetapa',
                      'Tarefa',
                    ].includes(task.tipo)
                  "
                  :class="{
                    'cronograma-setor-a': task.tipo === 'Setor',
                    'cronograma-subsetor-a': task.tipo === 'Subsetor',
                    'cronograma-place-a': task.tipo === 'Place',
                    'cronograma-etapa-a': task.tipo === 'Etapa',
                    'cronograma-subetapa-a': task.tipo === 'Subetapa',
                    'cronograma-tarefa-a': task.tipo === 'Tarefa',
                    'qde-cell': true,
                  }"
                  
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  :colspan="
                    [
                      'Setor',
                      'Subsetor',
                      'Place',
                      'Etapa',
                      'Subetapa',
                    ].includes(task.tipo)
                      ? 3
                      : null
                  "
                >
                  <!-- Comportamento para tipos diferentes de Tarefa -->
                  <template
                    v-if="
                      [
                        'Setor',
                        'Subsetor',
                        'Place',
                        'Etapa',
                        'Subetapa',
                      ].includes(task.tipo)
                    "
                  >
                    <input
                      v-model="task.qde1"
                      :id="'qde1-' + task.id"
                      ref="'qde1-' + task.id"
                      :class="[activeInputs[task.id] ? 'colorblack' : '']"
                      @input="activateInput(task.id)"
                      @blur="deactivateInput(task.id)"
                      @keydown.enter.prevent="handleQde1OptionClick(task)"
                      class="editable-cell"
                    />
                  </template>

                  <!-- Comportamento para Tarefa -->
                  <template v-else>
                    <i
                      v-if="isQdeVisible"
                      class="fas fa-plus-circle cronograma-tarefa-a"
                      style="color: black; font-size: 12px; cursor: pointer"
                      title="Adicionar"
                      @click="handleAdicionarTarefa(task.id)"
                    ></i>
                  </template>
                </td>
                <td
                  v-if="isQdeVisible && task.tipo === 'Tarefa'"
                  class="qde-cell"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                >
                  <i
                    class="fas fa-edit cronograma-tarefa-a"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Editar"
                    @click="handleEditarTarefa(task.id)"
                  ></i>
                </td>
                <td
                  v-if="isQdeVisible && task.tipo === 'Tarefa'"
                  class="qde-cell"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                >
                  <i
                    class="fas fa-times-circle cronograma-tarefa-a"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Excluir"
                    @click="handleExcluirTarefa(task.id)"
                  ></i>
                </td>

                <td
                  v-if="isUdeVisible"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :class="[
                    task.tipo === 'Projeto'
                      ? 'cronograma-projeto-a'
                      : task.tipo === 'Setor'
                      ? 'cronograma-setor-a'
                      : task.tipo === 'Subsetor'
                      ? 'cronograma-subsetor-a'
                      : task.tipo === 'Place'
                      ? 'cronograma-place-a'
                      : task.tipo === 'Etapa'
                      ? 'cronograma-etapa-a'
                      : task.tipo === 'Subetapa'
                      ? 'cronograma-subetapa-a'
                      : task.tipo === 'Tarefa'
                      ? 'cronograma-tarefa-a'
                      : '',
                    'ude-cell',
                  ]"
                >
                  <div
                    v-if="editingTaskId === task.id"
                    class="dropdown-wrapper"
                  >
                    <input
                      v-model="editableValue"
                      class="dropdown-input"
                      placeholder="Digite para filtrar"
                    />
                    <ul
                      v-if="filteredOptions && filteredOptions.length"
                      class="dropdown-options"
                    >
                      <li
                        v-for="(option, index) in filteredOptions"
                        :key="index"
                        :class="[
                          'dropdown-option',
                          task.tipo === 'Tarefa'
                            ? 'unidade-tarefa'
                            : task.tipo !== 'Tarefa'
                            ? 'colorblack'
                            : '',
                        ]"
                        @click="handleUdeOptionClick(option, task.tipo)"
                      >
                        {{ option.label }}
                      </li>
                    </ul>
                  </div>
                  <span v-else @click="enableUdeEdit(task)">{{
                    task.ude
                  }}</span>
                </td>
                <td
                  v-if="
                    isQde1Visible &&
                    [
                      'Projeto',
                      'Setor',
                      'Subsetor',
                      'Place',
                      'Etapa',
                      'Subetapa',
                      'Tarefa',
                    ].includes(task.tipo)
                  "
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :class="{
                    'cronograma-projeto-a': task.tipo === 'Projeto',
                    'cronograma-setor-a': task.tipo === 'Setor',
                    'cronograma-subsetor-a': task.tipo === 'Subsetor',
                    'cronograma-place-a': task.tipo === 'Place',
                    'cronograma-etapa-a': task.tipo === 'Etapa',
                    'cronograma-subetapa-a': task.tipo === 'Subetapa',
                    'cronograma-tarefa-a': task.tipo === 'Tarefa',
                    'qde1-cell': true,
                  }"
                >
                  <input
                    v-model="task.qde"
                    :id="'qde-' + task.id"
                    ref="'qde-' + task.id"
                    :class="[activeInputs[task.id] ? 'colorblack' : '']"
                    @input="activateInput(task.id)"
                    @blur="deactivateInput(task.id)"
                    @keydown.enter.prevent="handleQdeOptionClick(task)"
                    class="editable-cell"
                  />
                </td>
                <td
                  v-if="
                    isPorcentagemVisible &&
                    [
                      'Projeto',
                      'Setor',
                      'Subsetor',
                      'Place',
                      'Etapa',
                      'Subetapa',
                      'Tarefa',
                    ].includes(task.tipo)
                  "
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :class="{
                    'cronograma-projeto-a': task.tipo === 'Projeto',
                    'cronograma-setor-a': task.tipo === 'Setor',
                    'cronograma-subsetor-a': task.tipo === 'Subsetor',
                    'cronograma-place-a': task.tipo === 'Place',
                    'cronograma-etapa-a': task.tipo === 'Etapa',
                    'cronograma-subetapa-a': task.tipo === 'Subetapa',
                    'cronograma-tarefa-a': task.tipo === 'Tarefa',
                    'porcentagem-cell': true,
                  }"
                >
                  {{ task.porcentagem }}
                </td>
                <template v-if="task.tipo === 'Tarefa'">
                  <td
                    v-if="isstinicioVisible"
                    :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                    :class="['cronograma-tarefa-a', 'stinicio-cell']"
                  >
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        :checked="task.status_inicio === true"
                        @change="
                          (event) => {
                            task.status_inicio = event.target.checked ? 1 : 0;
                            editarStatusInicio(task);
                          }
                        "
                        class="status-checkbox"
                      />
                    </div>
                  </td>
                </template>
                <template v-if="task.tipo === 'Tarefa'">
                  <td
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                    v-if="isduracao00Visible"
                    :class="['cronograma-tarefa-a']"
                    class="duracao00-cell"
                  >
                    <input
                      v-model="task.duracao00"
                      :id="'duracao-' + task.id"
                      ref="'duracao-' + task.id"
                      @keydown.enter.prevent="onEnterduracao00(task)"
                      class="editable-cell"
                    />
                  </td>
                </template>
                <template v-else>
                  <td
                    v-if="isduracao00Visible"
                    :colspan="2"
                    :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                    :class="[
                      'cronograma-tarefa-a', // Classe padrão
                      task.tipo === 'Projeto'
                        ? 'cronograma-projeto-a'
                        : task.tipo === 'Setor'
                        ? 'cronograma-setor-a'
                        : task.tipo === 'Subsetor'
                        ? 'cronograma-subsetor-a'
                        : task.tipo === 'Place'
                        ? 'cronograma-place-a'
                        : task.tipo === 'Etapa'
                        ? 'cronograma-etapa-a'
                        : task.tipo === 'Subetapa'
                        ? 'cronograma-subetapa-a'
                        : '',
                    ]"
                    class="duracao00-cell"
                  >
                    <input
                      :value="calculateNumericDuration(task)"
                      :id="'duracao-' + task.id"
                      ref="'duracao-' + task.id"
                      readonly
                      class="editable-cell"
                    />
                  </td>
                </template>
                <td
                  v-if="isinicio00Visible"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :class="[
                    task.tipo === 'Projeto' ? 'cronograma-projeto-a' : '',
                    task.tipo === 'Setor' ? 'cronograma-setor-a' : '',
                    task.tipo === 'Subsetor' ? 'cronograma-subsetor-a' : '',
                    task.tipo === 'Place' ? 'cronograma-place-a' : '',
                    task.tipo === 'Etapa' ? 'cronograma-etapa-a' : '',
                    task.tipo === 'Subetapa' ? 'cronograma-subetapa-a' : '',
                    task.tipo === 'Tarefa' ? 'cronograma-tarefa-a' : '',
                  ]"
                  class="inicio00-cell"
                >
                  <template v-if="task.tipo === 'Tarefa'">
                    <!-- Input de calendário editável -->
                    <input
                      type="date"
                      :value="formatDateToISO(task.start)"
                      @change="editarInicio00($event, task)"
                      class="editable-cell"
                    />
                  </template>
                  <template v-else>
                    <!-- Apenas exibe a data formatada para tipos que não são 'Tarefa' -->
                    {{ formatDateToBrazilian(task.start) }}
                  </template>
                </td>
                <td
                  v-if="istermino00Visible"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :class="
                    task.tipo === 'Projeto'
                      ? ['cronograma-projeto-a']
                      : task.tipo === 'Setor'
                      ? ['cronograma-setor-a']
                      : task.tipo === 'Subsetor'
                      ? ['cronograma-subsetor-a']
                      : task.tipo === 'Place'
                      ? ['cronograma-place-a']
                      : task.tipo === 'Etapa'
                      ? ['cronograma-etapa-a']
                      : task.tipo === 'Subetapa'
                      ? ['cronograma-subetapa-a']
                      : task.tipo === 'Tarefa'
                      ? ['cronograma-tarefa-a']
                      : []
                  "
                  class="termino00-cell"
                >
                  {{ formatDateToBrazilian(task.end) }}
                </td>
                <td
                  v-if="isintervalo00Visible"
                  :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                  :colspan="task.tipo !== 'Tarefa' ? 3 : 1"
                  :class="[
                    task.tipo === 'Projeto' ? 'cronograma-projeto-a' : '',
                    task.tipo === 'Setor' ? 'cronograma-setor-a' : '',
                    task.tipo === 'Subsetor' ? 'cronograma-subsetor-a' : '',
                    task.tipo === 'Place' ? 'cronograma-place-a' : '',
                    task.tipo === 'Etapa' ? 'cronograma-etapa-a' : '',
                    task.tipo === 'Subetapa' ? 'cronograma-subetapa-a' : '',
                    task.tipo === 'Tarefa' ? 'cronograma-tarefa-a' : '',
                  ]"
                  class="intervalo00-cell"
                >
                  <template v-if="task.tipo === 'Tarefa'">
                    <input
                      v-model="task.intervalo00"
                      :id="'input-' + task.id"
                      ref="'input-' + task.id"
                      :class="{ typing: isTyping === task.id }"
                      @input="onInputChange(task)"
                      @keydown.enter.prevent="onEnterintervalo00(task)"
                      class="editable-cell"
                    />
                  </template>
                  <template v-else>
                    {{ task.intervalo00 }}
                  </template>
                </td>
                <template v-if="task.tipo === 'Tarefa'">
                  <td
                    v-if="isstterminoVisible"
                    :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                    :class="['cronograma-tarefa-a', 'sttermino-cell']"
                  >
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        :checked="task.status_termino === true"
                        @change="
                          (event) => {
                            task.status_termino = event.target.checked ? 1 : 0;
                            editarStatusTermino(task);
                          }
                        "
                        class="status-checkbox"
                      />
                    </div>
                  </td>
                </template>
                <template v-if="task.tipo === 'Tarefa'">
                  <td
                    v-if="isstterminoVisible"
                    :style="task.tipo === 'Tarefa' && Number(task.status) === 1 ? { backgroundColor: '#d6f4d2' } : {}"
                  
                    :class="['cronograma-tarefa-a', 'status-cell']"
                  >
                    <div class="checkbox-container">
                      <input
                        type="checkbox"
                        :checked="task.status === true"
                        @change="
                          (event) => {
                            task.status = event.target.checked ? 1 : 0;
                            editarStatus(task);
                          }
                        "
                        class="status-checkbox"
                      />
                    </div>
                  </td>
                </template>
                <td
                  v-for="date in dateRange"
                  :key="date + '-' + task.id"
                  class="mes-cell"
                >
                  <!-- Barra principal -->
                  <div
                    v-if="
                      isWithinRange(
                        date,
                        new Date(task.start),
                        new Date(task.end)
                      )
                    "
                    :class="`cronograma-${task.tipo.toLowerCase()}-b task-bar`"
                    :ref="`bar-${task.id}`"
                    :data-task-id="task.id"
                    :data-task-para="task.para"
                    :style="{
                      left:
                        (
                          getMonthFill(
                            date,
                            new Date(task.start),
                            new Date(task.end)
                          ).startProportion * 100
                        ).toFixed(2) + '%',
                      width:
                        (
                          getMonthFill(
                            date,
                            new Date(task.start),
                            new Date(task.end)
                          ).fillProportion *
                            100 +
                          (isFirstMonth(new Date(task.start), date)
                            ? (1 / totalBarWidth) * 100
                            : 1)
                        ).toFixed(2) + '%',
                      cursor: 'pointer',
                    }"
                    @contextmenu.prevent="
                      handleOpenIconContainer($event, task.id)
                    "
                  ></div>

                  <!-- Barra secundária (agora com ref para ser usado como endBar) -->
                  <div
                    v-if="
                      isWithinRange(
                        date,
                        new Date(task.start),
                        new Date(task.end)
                      )
                    "
                    class="secondary-bar"
                    :ref="`bar-${task.para}`"
                    :style="calculateSecondaryBarStyle(date, task)"
                  ></div>
                </td>
              </tr>
              <div
                v-if="iconContainerVisible"
                :style="{
                  top: `${iconContainerPosition.top}px`,
                  left: `${iconContainerPosition.left}px`,
                }"
                class="icon-container"
                style="display: flex; flex-direction: column; gap: 5px"
                @click.stop="handleCloseIconContainer"
              >
                <!-- Ícone de Início -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleArrastarInicioInicio"
                >
                  <i
                    class="fas fa-grip-lines"
                    style="font-size: 10px; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Início início</span>
                </div>

                <!-- Ícone de Término -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleArrastarTerminoInicio(taskId)"
                >
                  <i
                    class="fas fa-grip-lines"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Término início</span>
                </div>
                <!-- Ícone de Excluir Relacionamento -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleExcluirRelacionamento(taskId)"
                >
                  <i
                    class="fas fa-trash-alt"
                    style="
                      font-size: 10px;
                      cursor: pointer;
                      margin-right: 5px;
                      color: white;
                    "
                  ></i>
                  <span style="font-size: 12px">Excluir Relacionamento</span>
                </div>
                <!-- Ícone de Ordenar Tarefa -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarTarefa(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Tarefa</span>
                </div>
                <!-- Ícone de Ordenar Subetapa -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarSubetapa(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Subetapa</span>
                </div>
                <!-- Ícone de Ordenar Etapa -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarEtapa(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Etapa</span>
                </div>
                <!-- Ícone de Ordenar Place -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarPlace(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Place</span>
                </div>
                <!-- Ícone de Ordenar Subsetor -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarSubsetor(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Subsetor</span>
                </div>
                <!-- Ícone de Ordenar Setor -->
                <div
                  style="display: flex; align-items: center; cursor: pointer"
                  @click="handleOrdenarSetor(taskId)"
                >
                  <i
                    class="fas fa-sort"
                    style="font-size: 10px; cursor: pointer; margin-right: 5px"
                  ></i>
                  <span style="font-size: 12px">Ordenar Setor</span>
                </div>
              </div>
            </tbody>
            <div
              id="outside-focus-element"
              tabindex="-1"
              style="position: absolute; top: -9999px"
            ></div>
            <svg class="connection-lines" ref="connectionLines"></svg>
            <div v-if="isLoading" class="loading-indicator">
              <span class="spinner"></span>
            </div>
          </table>
        </div>
        <div
          id="alert-container"
          style="
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2000;
          "
        ></div>
      </div>
    </div>
    <div>
      <!-- Modal Editar Tarefa -->
      <EditarTarefaModal
        v-if="isModalEditarTarefaVisible"
        :task="selectedTask"
        :context="pageContext"
        @close="closeModalEditarTarefa"
        @fecharlEditarTarefaModal="fecharlEditarTarefaModal"
      />
    </div>
  </v-app>
</template>

<script>
import "./css.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import EditarTarefaModal from "./editartarefamodal/EditarTarefaModal.vue";
import { mapActions, mapGetters } from "vuex";


import {
  data,
  computed,
  methods,
  MetodosBotoes,
  MetodosLinhas,
  ReceberDatas,
  TaskVisibilityMethods,
  EditarCronogramaTarefa,
  Arrastar,
  MultiselectCronograma,
  Limpar,
  LarguraDropdown,
} from "./cronograma.js";

export default {
  name: "CronogramaFisico",

  components: {
    EditarTarefaModal,
    Multiselect,
  },

  data() {
    return {
      ...data(),
    };
  },

  props: {
    task: {
      type: [Object, Number],
      required: true,
    },
    context: {
      type: Object,
      required: true, // Torna obrigatório para garantir que seja recebido
    },
  },

  computed: {
    ...mapGetters(["tasks"]),
    ...computed, // Mantém os métodos computados existentes
    computed: {
      filteredOptions() {
        const searchValue = this.editableValue.toLowerCase().trim();
        const rawOptions = this.udeOptions; // Não precisa de toRaw se os dados são reativos

        if (!searchValue) {
          return rawOptions; // Retorna todas as opções se não houver filtro
        }

        return rawOptions.filter((option) =>
          option.label.toLowerCase().includes(searchValue)
        );
      },
    },
  },

  mounted() {
    //console.log('%c[DEBUG mounted] Início do mounted()', 'color: teal');
    //console.trace('⚠️ mounted()');
    this.fetchUdeOptions();
    this.fetchObras();
    this.fetchSetor();
    this.fetchSubsetor();
    this.fetchPlace();
    this.fetchEtapa();
    this.fetchSubetapa();
    this.fetchTarefa();
    this.fetchEmpresa();
    this.$nextTick(() => {
      LarguraDropdown();
      this.connectBars();
      this.setupScrollListener();
      if (this.$refs.contentWrapper) {
        this.$refs.contentWrapper.addEventListener(
          "scroll",
          this.syncHeaderScroll
        );
      }
    });
    // Define o estilo de escala inicial
    document.documentElement.style.setProperty(
      "--zoom-scale",
      this.zoomLevel / 100
    );
    this.tasks.forEach((task) => {
      this.initializeTaskProgress(task);
    });
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeIconContainer);
  },

  watch: {
    // Atualiza startDate sempre que selectedStartDateOption mudar
    selectedStartDateOption(newOption) {
      console.log("newOption", "01-" + newOption.value);
      if (newOption) {
        this.startDate = "01-" + newOption.value; // Atualiza o startDate real
      }
    },
    editableValue: {
      handler() {
        this.updateFilteredOptions();
      },
      immediate: true,
    },
    udeOptions: {
      handler() {
        this.updateFilteredOptions();
      },
      immediate: true,
    },

    // Atualiza startDate sempre que selectedStartDateOption mudar
    selectedEndDateOption(newOption) {
      if (newOption) {
        this.endDate = "01-" + newOption.value; // Atualiza o startDate real
      }
    },
  },

  methods: {
    async limpar() {
      await Limpar.limpar(this);
      this.fetchObras();
    },
    ...methods,
    ...MetodosBotoes,
    ...MetodosLinhas,
    ...ReceberDatas,
    ...TaskVisibilityMethods,
    ...EditarCronogramaTarefa,
    ...Arrastar,
    ...MultiselectCronograma,
    ...mapActions(["loadTasks", "updateTaskVisibility"]),
  },
};
</script>


  