<template>
  <div class="modal">
    <div class="modal-content">
      <!-- Cabeçalho -->
      <div class="modal-header">
        <img
          v-if="icon"
          :src="icon"
          alt="Ícone"
          class="modal-icon"
          :style="{ width: '30px', height: '30px' }"
        />
        <h3 class="modal-title">{{ title }}</h3>
        <div class="modal-actions">
          <i class="fas fa-times close-icon" @click="$emit('close')"></i>
        </div>
      </div>

      <!-- Corpo -->
      <div class="modal-body">
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirObra(projeto_id)"
            v-model="state.EditarTarefaCronograma.obra"
            placeholder=""
            class="input-field"
            style="cursor: pointer;"
            readonly
            title="Clique duas vezes para abrir a seleção de obra"
          />
        </div>
        <!-- Multiselect para Unidade -->
        <multiselect
          v-model="carregarUnidadeObra"
          :options="dataCarregarUnidadeObra || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_setor"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        />
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirSetor(setor_id)"
            v-model="state.EditarTarefaCronograma.setor"
            placeholder="Digite o setor"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de Setor"
          />
        </div>
        <multiselect
          v-model="carregarUnidadeSetor"
          :options="dataCarregarUnidadeSetor || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_setor"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirSubsetor(subsetor_id)"
            v-model="state.EditarTarefaCronograma.subsetor"
            placeholder="Digite o subsetor"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de subsetor"
          />
        </div>
        <multiselect
          v-model="carregarUnidadeSubsetor"
          :options="dataCarregarUnidadeSubsetor || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_subsetor"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirPlace(place_id)"
            v-model="state.EditarTarefaCronograma.place"
            placeholder="Digite o place"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de place"
          />
        </div>
        <multiselect
          v-model="carregarUnidadePlace"
          :options="dataCarregarUnidadePlace || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_place"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirEtapa(etapa_id)"
            v-model="state.EditarTarefaCronograma.etapa"
            placeholder="Digite o etapa"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de etapa"
          />
        </div>
        <multiselect
          v-model="carregarUnidadeEtapa"
          :options="dataCarregarUnidadeEtapa || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_etapa"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirSubetapa(subetapa_id)"
            v-model="state.EditarTarefaCronograma.subetapa"
            placeholder="Digite o subetapa"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de subetapa"
          />
        </div>
        <multiselect
          v-model="carregarUnidadeSubetapa"
          :options="dataCarregarUnidadeSubetapa || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_subetapa"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirTarefa(tarefa_id)"
            v-model="state.EditarTarefaCronograma.tarefa"
            placeholder="Digite o tarefa"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de tarefa"
          />
        </div>
        <multiselect
          v-model="carregarUnidadeTarefa"
          :options="dataCarregarUnidadeTarefa || []"
          label="label"
          track-by="value"
          @update:modelValue="Selecionar_unidade_tarefa"
          dense
          :multiple="false"
          placeholder="Selecione Unidade"
          class="custom-select select-carregar-unidade"
        ></multiselect>
        <div class="input-group input-a-EditarTarefaModal">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirSubtarefa(subtarefa_id)"
            v-model="state.EditarTarefaCronograma.subtarefa"
            placeholder="Digite o subtarefa"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de subtarefa"
          />
        </div>
        <div class="input-group input-a">
          <input
            id="name"
            type="text"
            @dblclick="handleAbrirOempresa(oempresa_id)"
            v-model="state.EditarTarefaCronograma.oempresa"
            placeholder="Digite o Empresa"
            class="input-field"
            style="cursor: pointer"
            readonly
            title="Clique duas vezes para abrir a seleção de empresa"
          />
        </div>
      </div>
      <!-- Rodapé (opcional) -->
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
          <button class="btn btn-primary" @click="onSave">Salvar</button>
          <button class="btn btn-secondary" @click="$emit('close')">
            Cancelar
          </button>
        </slot>
      </div>
    </div>
    <div>
      <!-- Modal Editar Tarefa -->
      <ObraModal
        v-if="isModalObraVisible"
        :task="selectedTask"
        @close="fecharObraModal"
        @fecharModalObra="fecharObraModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <SetorModal
        v-if="isModalSetorVisible"
        :task="selectedTask"
        @close="fecharSetorModal"
        @fecharModalSetor="fecharSetorModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <SubsetorModal
        v-if="isModalSubsetorVisible"
        :task="selectedTask"
        @close="fecharSubsetorModal"
        @fecharModalSubsetor="fecharSubsetorModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <PlaceModal
        v-if="isModalPlaceVisible"
        :task="selectedTask"
        @close="fecharPlaceModal"
        @fecharModalPlace="fecharPlaceModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <EtapaModal
        v-if="isModalEtapaVisible"
        :task="selectedTask"
        @close="fecharEtapaModal"
        @fecharModalPlace="fecharEtapaModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <SubetapaModal
        v-if="isModalSubetapaVisible"
        :task="selectedTask"
        @close="fecharSubetapaModal"
        @fecharModalPlace="fecharSubetapaModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <TarefaModal
        v-if="isModalTarefaVisible"
        :task="selectedTask"
        @close="fecharTarefaModal"
        @fecharModalPlace="fecharTarefaModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <SubtarefaModal
        v-if="isModalSubtarefaVisible"
        :task="selectedTask"
        @close="fecharSubtarefaModal"
        @fecharModalPlace="fecharSubtarefaModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <OempresaModal
        v-if="isModalOempresaVisible"
        :task="selectedTask"
        @close="fecharOempresaModal"
        @fecharModalPlace="fecharOempresaModal"
      />
    </div>
  </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./editartarefa.css";
import ObraModal from "../../obra/ObraModal.vue";
import SetorModal from "../../setor/SetorModal.vue";
import SubsetorModal from "../../subsetor/SubsetorModal";
import PlaceModal from "../../place/PlaceModal.vue";
import EtapaModal from "../../etapa/EtapaModal.vue";
import SubetapaModal from "../../subetapa/SubetapaModal.vue";
import TarefaModal from "../../tarefa/TarefaModal.vue";
import SubtarefaModal from "../../subtarefa/SubtarefaModal.vue";
import OempresaModal from "../../oempresa/OempresaModal.vue";
import { LarguraDropdown, data, EditarTarefa } from "./editartarefa.js";

export default {
  name: "EditarTarefaModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {
    ObraModal,
    SetorModal,
    SubsetorModal,
    PlaceModal,
    EtapaModal,
    SubetapaModal,
    TarefaModal,
    SubtarefaModal,
    OempresaModal,
    Multiselect,
  },
  props: {
    title: {
      type: String,
      default: "Editar Tarefa do Cronograma",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: Object,
      required: true,
    },
    context: {
      type: Object,
      required: true, // Torna obrigatório para garantir que seja recebido
    },
  },

  watch: {
    task: {
      immediate: true,
      handler(newTask) {
        if (newTask) {
          this.fetchEditarTarefa(newTask);
          this.fetchObraUnidade(newTask);
          this.fetchSetorUnidade(newTask);
          this.fetchSubsetorUnidade(newTask);
          this.fetchPlaceUnidade(newTask);
          this.fetchEtapaUnidade(newTask);
          this.fetchSubetapaUnidade(newTask);
          this.fetchTarefaUnidade(newTask);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      LarguraDropdown();
    });
  },
  methods: {
    ...EditarTarefa,
  },
};
</script>
  